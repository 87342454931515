import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import formatTime from '../lib/formatTime'
import { mediaMaxWidth } from '../components/styles/media'
import Layout from '../components/Layout'

const CategoryPageLayout = styled(Layout)`
  padding-top: 108px;
  ${mediaMaxWidth('tablet')`
      padding-top: 106px;
    `}
  ${mediaMaxWidth('mobile')`
      padding-top: 92px;
    `}
  .category-title {
    margin: 36px 0px;
    text-align: center;
    ${mediaMaxWidth('tablet')`
      margin: 28px 0px;
    `}
    ${mediaMaxWidth('mobile')`
      margin: 20px 0px;
    `}
  }
  .category-block {
    --max-col: 1fr;
    --min-col: 20px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 36px;
    grid-template-columns: repeat(12, minmax(var(--min-col), var(--max-col)));
    grid-template-rows: auto;
    width: 100%;
    ${mediaMaxWidth('tablet')`
      grid-row-gap: 28px;
      padding: 0 20px;
    `}
    ${mediaMaxWidth('mobile')`
      grid-column-gap: 0px;
      grid-row-gap: 20px;
    `}
    > * {
      grid-column: span 4;
      ${mediaMaxWidth('tablet')`
        grid-column: span 6;
      `}
      ${mediaMaxWidth('mobile')`
        grid-column: span 12;
      `}
    }
  }
`

const Category = ({
  pageContext: { category },
  data: {
    allContentfulRecipe: { edges: recipes },
  },
  ...rest
}) => {
  // quick fix for pluralization of "Side" to "Sides"
  if (category === 'Side') category += 's'
  if (category == 'Sweets') category = 'Desserts & Sweets'
  return (
    <CategoryPageLayout>
      <h2 className="category-title">{category}</h2>
      {category === 'Recipes In Development' && (
        <p
          style={{
            textAlign: 'center',
            marginBottom: '1.5em',
            marginTop: '-1.5em',
          }}
        >
          This category is reserved for recipes currently in development. Here
          you can test these recipes along with our team and help us by
          commenting with your questions, results, and suggestions.
        </p>
      )}
      <div className="category-block">
        {recipes.map(({ node: recipe }, i) => {
          return <Tile recipe={recipe} key={i} />
        })}
      </div>
    </CategoryPageLayout>
  )
}
export default Category

const RecipeTile = styled(Link)`
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-sizing: border-box;
  color: #404040;
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
  transition: transform 300ms ease 100ms;

  > .tile-image-container {
    background: #e0e0e0;
    border-radius: 5px 5px 0 0;
    height: 202px;
    ${mediaMaxWidth('tablet')`
      min-height: 198px;
      height: 100%;
    `}
    ${mediaMaxWidth('mobile')`
      min-height: 187px;
    `}
    img {
      background: #e0e0e0;
      border-radius: 5px 5px 0 0;
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  > .tile-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 112px;
    padding: 16px;
    ${mediaMaxWidth('mobile')`
      min-height: 88px;
    `}
    .tile-text-heading {
      /* to truncate title text; see resouce: https://css-tricks.com/line-clampin/ */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      ${mediaMaxWidth('mobile')`
        display: static;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: auto;
      `}
      > h3 {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .time {
      color: #757575;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      margin: 0;
      ${mediaMaxWidth('mobile')`
        margin-top: 16px;
      `}
    }
  }
`
const Tile = ({ recipe }) => {
  const {
    title,
    sauceTitle: subtitle,
    mediaUrl,
    slug,
    totalTimeMinutes,
    prepTimeMinutes,
    cookTimeMinutes,
    inactivePrepTimeMinutes,
    contentfulMedia,
  } = recipe
  const time = formatTime(totalTimeMinutes)
  const image =
    contentfulMedia && contentfulMedia.file && contentfulMedia.file.url
      ? contentfulMedia.file.url
      : mediaUrl
  return (
    <RecipeTile to={`/recipes/${recipe.slug}`}>
      <div className="tile-image-container">
        <img src={image.replace('http:', 'https:')} alt={title} />
      </div>
      <div className="tile-text">
        <div className="tile-text-heading">
          <h3>
            {title}
            {subtitle && ` with ${subtitle}`}
          </h3>
        </div>
        <p className="time">{time}</p>
      </div>
    </RecipeTile>
  )
}

export const pageQuery = graphql`
  query($category: String) {
    allContentfulRecipe(
      filter: { categories: { elemMatch: { subCategory: { eq: $category } } } }
    ) {
      totalCount
      edges {
        node {
          title
          sauceTitle
          mediaUrl
          # contentfulMedia {
          #   file {
          #     url
          #   }
          # }
          slug
          totalTimeMinutes
          prepTimeMinutes
          cookTimeMinutes
          inactivePrepTimeMinutes
        }
      }
    }
  }
`
