const formatTime = timeInMinutes => {
  var date = new Date(null)
  date.setMinutes(timeInMinutes)
  let hours = date.toISOString().substring(11, 13)
  let minutes = date.toISOString().substring(14, 16)
  hours = hours < 1 ? null : hours < 10 ? hours.substring(1) : hours
  minutes = minutes < 1 ? null : minutes < 10 ? minutes.substring(1) : minutes
  if (hours && minutes) return `${hours}h ${minutes}m`
  if (hours && !minutes) return `${hours}h`
  if (!hours && minutes) return `${minutes}m`
}

export default formatTime
